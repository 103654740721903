import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NavigationHeader from '../components/molecules/NavigationHeader';
import SocialLinks from '../components/molecules/SocialLinks';
import ProjectCard from '../components/molecules/ProjectCard';
import ProjectModal from '../components/molecules/ProjectModal';
import { useTheme } from "../contexts/ThemeContext";
import { PROJECTS } from '../utils/constants';
import {formatRequests, sortByDate} from '../utils/utils';
import SliderToggle from "../components/molecules/CustomSlider";

const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const OuterProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
`;

const SortProjectsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  margin: 4.5em 1em 0.5em;
`;

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 1em;
  width: 100%;
  padding: 1em;
`;

const ProjectText = styled.p`
  font-size: 1.1em;
  color: #777;
  margin: 2em auto;
  text-align: center;
`;

const Projects = () => {
  const { theme } = useTheme();
  const [selectedProject, setSelectedProject] = useState(null);
  const [sortBy, setSortBy] = useState('popularity');
  const [projects, setProjects] = useState(PROJECTS);
  const [animateCards, setAnimateCards] = useState(false);

  useEffect(() => {
    const sortedProjects = [...PROJECTS].sort((a, b) => {
      return sortBy === 'date' ? sortByDate(a, b) : b.requests - a.requests;
    });
    setProjects(sortedProjects);
  }, [sortBy]);

useEffect(() => {
    setAnimateCards(false);
    const timer = setTimeout(() => setAnimateCards(true), 100); // A short delay before applying the animation

    return () => clearTimeout(timer); // Clean up the timeout
}, [sortBy]);

  const handleProjectClick = project => setSelectedProject(project);
  const handleCloseModal = () => setSelectedProject(null);

  const totalRequests = projects.reduce((acc, project) => acc + project.requests, 0);
  // const formatRequests = requests => requests < 1000 ? `${requests}` : `${(requests / 1000).toFixed(requests % 1000 === 0 ? 0 : 1)}k`;

  return (
      <AppContainer theme={theme}>
        <NavigationHeader />
        <OuterProjectsContainer>
          <SortProjectsContainer theme={theme}>
           <SliderToggle handleSortChange={setSortBy} />
          </SortProjectsContainer>
          <ProjectsContainer>
            {projects.map((project, index) => (
                <ProjectCard
                    key={project.id}
                    project={project}
                    onClick={() => handleProjectClick(project)}
                    animation={animateCards}
                    index={index}
                />
            ))}
          </ProjectsContainer>
          <ProjectText>{projects.length} projects total ({formatRequests(totalRequests)} requests)</ProjectText>
        </OuterProjectsContainer>
        {selectedProject && <ProjectModal project={selectedProject} onClose={handleCloseModal} />}
        <SocialLinks />
      </AppContainer>
  );
};

export default Projects;
