import React, { useEffect, useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';

const fadeDownIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GlobalStyle = createGlobalStyle`
  body, p {
    font-family: 'Inter', sans-serif;
  }

  a {
    text-decoration: none;
  }

  // Add more global styles as needed
`;

const StickyHeaderContainer = styled.div`
  position: fixed;
  top: 4em;
  width: 100%;
  background-color: #222;
  left: 2em;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
`;

const StickyHeaderText = styled.h1`
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
  padding: 5px 10px;
`;

const StickyDiv = styled.div`
  z-index: 999;
  background-color: #111;
  animation: ${fadeDownIn} 0.3s ease-in-out;
`;

const HeaderH1 = styled.h1`
  font-size: 30px;
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
`;

const StyledLink = styled.a`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  text-decoration: none;
`;

const StickyHeader = ({ isMenuOpen }) => {
    const [headerText, setHeaderText] = useState('');
    const [show, setShow] = useState(false);
    const { theme } = useTheme();

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let newHeaderText = '';

            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                const rect = section.getBoundingClientRect();
                // cutoff is 130 if screen is mobile, 170 if not
                const cutoff = window.innerWidth < 768 ? 130 : 175;
                if (rect.top < cutoff) {
                    newHeaderText = section.querySelector('h2').textContent;
                }
            }

            if (newHeaderText === '') {
                setShow(false);
            } else {
                setShow(true);
            }
            setHeaderText(newHeaderText);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <StickyHeaderContainer isMenuOpen={isMenuOpen}>
            {show && (
                <>
                    <StickyDiv>
                        <HeaderH1>Silas Nevstad</HeaderH1>
                        <h2>
                            Boston, MA | nevstads@gmail.com | <StyledLink href="https://www.linkedin.com/in/silas-nevstad-3091a420b/">LinkedIn</StyledLink> | <StyledLink href="https://github.com/silasnevstad/">Github</StyledLink>
                        </h2>
                    </StickyDiv>
                    <StickyHeaderText>{headerText}</StickyHeaderText>
                </>
            )}
        </StickyHeaderContainer>
    );
};

export default StickyHeader;
