export const formatRequests = (requests, special=false) => {
    if (requests === 0) {
        return "Unreleased"
    } else if (requests > 1000000) {
        if (special) {
            return (requests / 1000000).toFixed(1) + 'm';
        }
        // if 1.0m ends in .0, show more precision
        if (requests % 1000000 === 0) {
            return (requests / 1000000).toFixed(1) + 'm';
        }
        return (requests / 1000000).toFixed(2) + 'm';
    } else if (requests > 10000) {
        if (special) {
            return (requests / 1000).toFixed(1) + 'k';
        }
        return (requests / 1000).toFixed(1) + 'k';
    } else if (requests > 1000) {
        return (requests / 1000).toFixed(2) + 'k';
    }

    return requests;
};

export const getProjectRequestsText = (special= false, unreleased = false) => {
    return special ? 'downloads' : unreleased ? '' : 'visits';
}

const parseDate = (dateString) => {
    const months = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
    };

    if (!dateString) return null;

    if (dateString.split(' ').length === 2) {
        dateString = `1 ${dateString}`;
    }
    const [day, monthStr, year] = dateString.split(' ');
    const month = months[monthStr];

    return new Date(year, month, day);
};

export const sortByDate = (a, b) => {
    const dateA = parseDate(a.date);
    const dateB = parseDate(b.date);

    if (!dateA || !dateB) return dateA ? -1 : 1;

    return dateB - dateA;
};

export const getLanguageColors = (language) => {
    // lowercase language
    switch (language.toLowerCase()) {
        case 'javascript':
            return ['#b2a640', 'rgba(252,239,86,0.2)'];
        case 'typescript':
            return ['#2b7489', 'rgba(43,116,137,0.3)'];
        case 'python':
            return ['#81a1f7', 'rgba(129,161,247,0.3)'];
        case 'java':
            return ['#f1a017', 'rgba(241,160,23,0.3)'];
        case 'c++':
            return ['#f34b7d', 'rgba(243,75,125,0.3)'];
        case 'c#':
            return ['#178600', 'rgba(23,134,0,0.3)'];
        case 'react':
            return ['#c26ceb', 'rgba(194,108,235,0.3)'];
        case 'node.js':
            return ['#796cff', 'rgba(145,139,223,0.3)'];
        case 'html':
            return ['#BBB09B', 'rgba(187,176,155,0.3)'];
        case 'css':
            return ['#F49F0A', 'rgba(244,159,10,0.3)'];
        case 'swift':
            return ['#E1634A', 'rgba(225,99,74,0.3)'];
        case 'react native':
            return ['#609d83', 'rgba(169,240,209,0.3)'];
        case 'go':
            return ['#00add8', 'rgba(0,173,216,0.3)'];
        case 'neo4j':
            return ['#008CC1', 'rgba(0,140,193,0.3)'];
        case 'aws':
            return ['#ff9901', 'rgba(255,153,1,0.3)'];
        case 'firebase':
            return ['#ffca29', 'rgba(255,202,41,0.3)'];
        default:
            return ['#fff', 'rgba(255,255,255,0.3)'];
    }
};