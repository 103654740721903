import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { FaBars } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";

const Container = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: transparent;
`;

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .8em 1em;
`;

const Logo = styled.h1`
    font-size: 1.5rem;
        
    @media (max-width: 768px) {
        font-size: 1.3rem;
    }
`;

const NavLinks = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.2em;
`;

const MobileNavLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -15em;
  align-items: flex-start;
  gap: 1.5em;
`;

const NavLink = styled(RouterLink)`
  font-family: 'PoppinsMedium', serif;
  text-decoration: none;
  font-size: 1.05rem;
  padding: 2px 5px;
  opacity: 0.9;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 0;
    height: 1.5px;
    background: ${({ theme }) => theme.colors.primary};
    transition: width 0.3s;
  }
  
  &:hover {
    opacity: 1;
  }
  
  &:hover::after {
    width: calc(100% - 10px); // subtract twice the padding from the width
  }
`;

const MobileNavLink = styled(NavLink)`
  font-size: 1.5em;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
`;

const MobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PoppinsMedium', serif;
  font-size: 1.05rem;
  padding: 8px;
  border-radius: 20px;
  opacity: 0.9;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primaryFade};
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const MobileButtonIcon = styled(FaBars)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;


function useMobileDetect() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handler = () => setIsMobile(window.innerWidth < 768);
        // Call handler right away so state gets updated with initial window size
        handler();
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    return isMobile;
}

const NavigationHeader = () => {
    const { theme } = useTheme();
    const isMobile = useMobileDetect();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    if (isMobile) {
        return (
            <Container>
                <InnerContainer>
                    <Logo style={{ color: theme.colors.primary }}></Logo>
                    <MobileButton onClick={toggleModal} theme={theme}>
                        <MobileButtonIcon theme={theme} />
                    </MobileButton>
                    {isModalOpen && (
                        // Assume Modal is a full screen modal component for navigation
                        <Modal onClose={toggleModal} theme={theme}>
                            <MobileNavLinks onClick={toggleModal}>
                                <MobileNavLink theme={theme} to="/">Home</MobileNavLink>
                                <MobileNavLink theme={theme} to="/portfolio">Portfolio</MobileNavLink>
                                <MobileNavLink theme={theme} to="/resume">Resume</MobileNavLink>
                                <MobileNavLink theme={theme} to="/chess">Chess</MobileNavLink>
                                <MobileNavLink theme={theme} to="/contact">Contact</MobileNavLink>
                            </MobileNavLinks>
                        </Modal>
                    )}
                </InnerContainer>
            </Container>
        );
    }

    // Non-mobile navigation
    return (
        <Container>
            <InnerContainer>
                <Logo style={{ color: theme.colors.primary }}></Logo>
                <NavLinks>
                    <NavLink theme={theme} to="/">Home</NavLink>
                    <NavLink theme={theme} to="/portfolio">Portfolio</NavLink>
                    <NavLink theme={theme} to="/resume">Resume</NavLink>
                    <NavLink theme={theme} to="/chess">Chess</NavLink>
                    <NavLink theme={theme} to="/contact">Contact</NavLink>
                </NavLinks>
            </InnerContainer>
        </Container>
    );
}

export default NavigationHeader;