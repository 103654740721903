import React from 'react';
import styled, { keyframes } from 'styled-components';

const CenterScreen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ripple = keyframes`
  0% {
    background-color: transparent;
  }
  30% {
    background-color: var(--cell-color);
  }
  60% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
`;

const MosaicLoader = styled.div`
  --cell-size: 64px;
  --cell-spacing: 1px;
  --border-width: 1px;
  --cells: 4;
  --total-size: calc(var(--cells) * (var(--cell-size) + 2 * var(--cell-spacing)));
  display: flex;
  flex-wrap: wrap;
  width: var(--total-size);
  height: var(--total-size);
  margin-top: -7em;
`;

const Cell = styled.div`
  --cell-color: ${({ color }) => color || 'white'};
  flex: 0 0 var(--cell-size);
  margin: var(--cell-spacing);
  background-color: transparent;
  box-sizing: border-box;
  border: var(--border-width) solid var(--cell-color);
  animation: ${ripple} 1.5s ease infinite;
  animation-delay: ${({ delay }) => delay || '0ms'};
`;

const ChessLoader = () => {
    const colors = ['#769656', '#eeeed2'];
    // Define the pattern of your loader here
    const cellColors = [
        colors[0], colors[1], colors[0], colors[1],
        colors[1], colors[0], colors[1], colors[0],
        colors[0], colors[1], colors[0], colors[1],
        colors[1], colors[0], colors[1], colors[0],
    ];

    return (
        <CenterScreen>
            <MosaicLoader>
                {cellColors.map((color, index) => (
                    <Cell key={index} color={color} delay={`${(index % 6) * 100}ms`} />
                ))}
            </MosaicLoader>
        </CenterScreen>
    );
}

export default ChessLoader;
