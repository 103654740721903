import React from 'react';
import styled from 'styled-components';
import CustomText from "../components/atoms/CustomText";
import CustomButton from "../components/atoms/CustomButtom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const LoadingNoTheme = () => {
    return (
        <Container>
            Loading
        </Container>
    );
}

export default LoadingNoTheme;