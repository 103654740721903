import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const SocialLinksContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;

  z-index: 99;
`;

const SocialLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #fff;
  transition: all 0.3s;
  opacity: 0.9;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0px);
    height: calc(100% + 0px);
    border-radius: 50%;
    transition: all 0.3s;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.1);

    &::before {
      transform: scale(1.1);
    }
  }
`;


const LinkedinLink = styled(SocialLink)`
  background-color: #2d76b0;

  &::before {
    border: 1px solid #2d76b0;
  }
`;

const GithubLink = styled(SocialLink)`
  background-color: #333333;

  &::before {
    border: 1px solid #333333;
  }
`;

const XTwitterLink = styled(SocialLink)`
    background-color: #14171A;
    
    &::before {
        border: 1px solid #657786;
    }
`;


const SocialLinks = () => {
    return (
        <SocialLinksContainer>
            <LinkedinLink href="https://www.linkedin.com/in/silas-nevstad/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin style={{ fontSize: '2.3rem' }} />
            </LinkedinLink>
            <GithubLink href="https://github.com/silasnevstad" target="_blank" rel="noopener noreferrer">
                <FaGithub style={{ fontSize: '2.3rem' }} />
            </GithubLink>
            <XTwitterLink href="https://twitter.com/Nevstads" target="_blank" rel="noopener noreferrer">
                <FaXTwitter style={{ fontSize: '2rem' }} />
            </XTwitterLink>
        </SocialLinksContainer>
    );
};

export default SocialLinks;
