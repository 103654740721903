import React, {useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import CustomText from "../components/atoms/CustomText";
import TypingText from "../components/molecules/TypingText";
import NavigationHeader from "../components/molecules/NavigationHeader";
import ProjectCard from "../components/molecules/ProjectCard";
import {PERSONAL_SITE_REQUESTS, PROJECTS} from "../utils/constants";
import {sortByDate} from "../utils/utils";
import ProjectModal from "../components/molecules/ProjectModal";
import SocialLinks from "../components/molecules/SocialLinks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 75%;
  overflow: hidden;
  margin-top: 100px;
  padding-bottom: 100px;
  
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 50px;
  }
`;

const TitleText = styled(CustomText)`
    font-size: 2.2em;
`;

const SubtitleText = styled(CustomText)`
    font-size: 1.4em;
    margin-top: 1em;
`;

const HomeTitleIcon = styled.svg`
    margin-right: 0.5em;
    vertical-align: middle;
`;

const HomeProjectsContainer = styled.div`
  display: grid;
  gap: 1em;
  justify-content: center;
  width: 100%;
  margin-top: 1em;

  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`;

const Divider = styled.div`
    width: 100%;
    height: 20px;
`;

const MobileDivider = styled.div`
    width: 100%;
    height: 0;
`;

const FooterText = styled(CustomText)`
  position: fixed;
  bottom: 5px;
  left: 10px;
  align-self: center;
  font-size: 0.9rem;
  opacity: 0.7;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const Home = () => {
    const { theme } = useTheme();
    const homeProjectsContainerRef = useRef(null);

    const sortedByRequests = useMemo(() => [...PROJECTS].sort((a, b) => b.requests - a.requests), []);
    const sortedByDate = useMemo(() => [...PROJECTS].sort(sortByDate), []);

    const personalSiteRequests = PERSONAL_SITE_REQUESTS.substring(0, PERSONAL_SITE_REQUESTS.length - 3) + "." + PERSONAL_SITE_REQUESTS.substring(PERSONAL_SITE_REQUESTS.length - 3, PERSONAL_SITE_REQUESTS.length - 1) + "k";

    const mostPopularProjects = sortedByRequests.slice(0, 3);
    const mostRecentProjects = sortedByDate.slice(0, 3);

    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        console.log("Selected project: ", project);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    return (
        <Container theme={theme}>
            <NavigationHeader />
            <InnerContainer>
                <TitleText type="bold">Hi, I'm <span style={{ color: theme.colors.primary }}>Silas</span></TitleText>
                <TypingText
                    startingText="I'm "
                    style={{ fontSize: "1.4em" }}
                    typingSpeed={100}
                    erasingSpeed={50}
                />

                <SubtitleText>
                    <HomeTitleIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></HomeTitleIcon>
                    Popular Projects
                </SubtitleText>
                <HomeProjectsContainer ref={homeProjectsContainerRef}>
                    {mostPopularProjects.map((project, index) => (
                        <ProjectCard key={index} project={project} onClick={() => handleProjectClick(project)} index={index} animation={true} />
                    ))}
                </HomeProjectsContainer>
                <Divider />
                <SubtitleText>
                    <HomeTitleIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></HomeTitleIcon>
                    Recent Projects
                </SubtitleText>
                <HomeProjectsContainer ref={homeProjectsContainerRef}>
                    {mostRecentProjects.map((project, index) => (
                        <ProjectCard key={index} project={project} onClick={() => handleProjectClick(project)} index={index} animation={true} />
                    ))}
                </HomeProjectsContainer>
                <MobileDivider />
                {selectedProject && <ProjectModal project={selectedProject} onClose={handleCloseModal} />}

                <FooterText type="medium">{window.innerWidth > 768 && "2022 - 2024 Silas Nevstad © "}{personalSiteRequests} requests</FooterText>
                <SocialLinks />
            </InnerContainer>
        </Container>
    );
}

export default Home;