import React from 'react';
import styled from 'styled-components';
import { getLanguageColors } from '../../utils/utils';
import CustomText from "../atoms/CustomText";

const Languages = styled.p`
  font-size: 0.95rem;
  opacity: 0.95;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  &.project-languages:hover {
    opacity: 1;
  }
`;

const LanguageSpan = styled(CustomText)`
  font-size: 1rem;
  padding: 2px 8px;
  border-radius: 15px;
  margin-right: 5px;
`;

const ProjectLanguage = ({ className, language }) => {
    const colors = getLanguageColors(language);
    return (
        <LanguageSpan style={{ color: colors[0], backgroundColor: colors[1], marginRight: '10px' }} type="medium">
            {language}
        </LanguageSpan>
    )
}

const ProjectLanguages = ({ className, languages }) => {
    return (
        <Languages className={className}>
            {languages.map((language, index) =>
                <ProjectLanguage key={index} language={language} />
            )}
        </Languages>
    )
}

export default ProjectLanguages;