import React, {useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import ProjectLanguages from "./ProjectLanguages";
import CountUp from 'react-countup';
import {formatRequests, getProjectRequestsText} from "../../utils/utils";

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 18px;
  min-height: 140px;
  padding: 15px;
  margin: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.text};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px, rgba(0, 0, 0, 0.2) 0px 5px 10px -3px, rgba(0, 0, 0, .2) 0px -3px 0px inset;
  position: relative;
  opacity: 0;
  will-change: transform, opacity;
  animation: ${({ animation }) => animation ? 'growFadeIn 0.4s ease-out forwards' : 'none'};

  @keyframes growFadeIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px, rgba(0, 0, 0, 0.3) 0px 5px 10px -3px, rgba(0, 0, 0, 0.3) 0px -3px 0px inset;
    background-color: ${({ theme }) => theme.colors.fifth};
    transform: scale(1.02) translateY(-5px);
  }
  
  @media (max-width: 768px) {
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 6px, rgba(0, 0, 0, 0.4) 0px 5px 10px -3px, rgba(0, 0, 0, .4) 0px -3px 0px inset, rgba(0, 0, 0, .2) 0px 0px 0px 1px inset;
    padding: 12px;
    margin: 5px;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.1em;
`;

const ProjectTitle = styled(CustomText)`
  font-size: 1.5em;
`;

const ProjectVersion = styled(CustomText)`
    display: flex;
    align-items: center;
    gap: 0.3em;
    font-size: 1em;
    opacity: 0.8;
    color: ${({theme}) => theme.colors.stroke};
`;

const ProjectVersionLabel = styled(CustomText)`
    margin-top: 0.2em;
    font-size: .9em;
    opacity: 0.9;
    color: ${({ theme }) => theme.colors.stroke};
`;

const ProjectDate = styled(CustomText)`
  font-size: 0.8em;
  justify-self: flex-end;
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.stroke};
`;

const ProjectLogo = styled.img`
  align-self: center;
  width: 100px;
  border-radius: 20px;
`;

const ProjectCard = ({ project, onClick, animation, index }) => {
    const { theme } = useTheme();
    const [requests, setRequests] = useState(project.requests);
    const [isCountDone, setIsCountDone] = useState(false);

    const animationDelay = `${index * 60}ms`;

    return (
        <Card
            theme={theme}
            onClick={onClick}
            animation={animation}
            style={{ animationDelay }}
        >
            <ProjectInfo>
                <ProjectTitle theme={theme} type="medium">{project.title}</ProjectTitle>
                {isCountDone ? (
                    <ProjectVersion theme={theme}>
                        {formatRequests(requests, project.title === "VSCode GPT").split(' ')[0] + ' '}
                        <ProjectVersionLabel theme={theme}>
                            {formatRequests(requests, project.title === "VSCode GPT").split(' ')[1]}
                        </ProjectVersionLabel>
                    </ProjectVersion>
                ) : (
                    <ProjectVersion theme={theme}>
                        <CountUp
                            start={0}
                            end={requests}
                            duration={1.5}
                            formattingFn={number => formatRequests(number, project.title === "VSCode GPT")}
                            onComplete={() => setIsCountDone(true)}
                        />
                        <ProjectVersionLabel theme={theme}>
                            {getProjectRequestsText(project.title === "VSCode GPT", project.requests === 0)}
                        </ProjectVersionLabel>
                    </ProjectVersion>
                )}
                <ProjectLanguages className="project-languages" languages={project.languages} theme={theme} />
                <ProjectDate theme={theme}>{project.date}</ProjectDate>
            </ProjectInfo>
            {project.logo !== '' && <ProjectLogo src={require('../../assets/images/logos/' + project.logo)} alt='Project Logo' theme={theme} />}
        </Card>
    );
}

export default ProjectCard;