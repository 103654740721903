import React from 'react';
import styled from 'styled-components';
import ProjectCardExpanded from './ProjectCardExpanded';
import { useTheme } from '../../contexts/ThemeContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: 5px;
  border-radius: 18px;
  width: 50%;
  //box-shadow: rgba(51, 51, 51, 0.6) 0px 4px 6px, rgba(51, 51, 51, 0.6) 0px 5px 10px -3px, rgba(51, 51, 51, 0.5) 0px -3px 0px inset;
   border: 2px solid ${({ theme }) => theme.colors.borderFade};
  z-index: 1001;
  // overflow-y: scroll;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (prefers-color-scheme: light) {
    // background-color: #fff;
    // color: #222;
    // border: none;

    .project-languages {
      // background-color: #fff;
      // color: #222;
    }
  }
`;

const CloseButton = styled.button`
  --shadow: 0 5px 15px 0 #00000026;
  --shadow-active: 0 5px 5px 0 #00000026;
  --border-radius-main: 10px;
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1rem;
  height: 1rem;
  color: transparent;
  font-size: 0;
  cursor: pointer;
  background-color: #ff5f5f80;
  border: none;
  border-radius: var(--border-radius-main);
  -webkit-border-radius: var(--border-radius-main);
  -moz-border-radius: var(--border-radius-main);
  -ms-border-radius: var(--border-radius-main);
  -o-border-radius: var(--border-radius-main);
  transition: .2s ease all;
  -webkit-transition: .2s ease all;
  -moz-transition: .2s ease all;
  -ms-transition: .2s ease all;
  -o-transition: .2s ease all;
  z-index: 1002;

  &:hover {
    width: 1.2rem;
    height: 1.2rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #ff5f5fcc;
    box-shadow: var(--shadow-active);
    -moz-box-shadow: var(--shadow-active);
    -webkit-box-shadow: var(--shadow-active);
  }

  &:active {
    width: 1.1rem;
    height: 1.1rem;
    font-size: .9rem;
    color: #ffffffde;
    --shadow-btn-close: 0 3px 3px 0 #00000026;
    box-shadow: var(--shadow-btn-close);
    -moz-box-shadow: var(--shadow-btn-close);
    -webkit-box-shadow: var(--shadow-btn-close);
  }

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }

  @media (prefers-color-scheme: light) {
    // background-color: #ff5f5fff;
    // color: #222;
    // box-shadow: var(--shadow);
    // -moz-box-shadow: var(--shadow);
    // -webkit-box-shadow: var(--shadow);

    &:hover {
      // background-color: #ff5f5fdd;
      // box-shadow: var(--shadow-active);
      // -moz-box-shadow: var(--shadow-active);
      // -webkit-box-shadow: var(--shadow-active);
    }
  }
`;

const ProjectModal = ({ project, onClose }) => {
    const { theme } = useTheme();

    return (
        <ModalOverlay onClick={onClose}>
            <ModalWrapper onClick={(e) => e.stopPropagation()} theme={theme}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <ProjectCardExpanded project={project} />
            </ModalWrapper>
        </ModalOverlay>
    );
};

export default ProjectModal;
