import React, { createContext, useContext, useState, useEffect } from 'react';

const lightTheme = {
    name: 'light',
    colors: {
        dark: '#2E3D49',
        white: '#FFFFFF',
        background: '#f5f5f5',
        backgroundSecondary: '#fff',
        text: '#2E3D49',
        primary: '#6b72fd',
        primaryFade: 'rgba(107, 114, 253, 0.6)',
        primaryFaded: 'rgba(107, 114, 253, 0.2)',
        lightBorder: '#bdbdbd',
        border: '#595959',
        borderFade: 'rgba(89, 89, 89, 0.3)',
        stroke: '#DDDDDD',
        strokeFade: 'rgba(176, 190, 197, 0.5)',
        strokeFaded: 'rgba(176, 190, 197, 0.3)',
        purple: '#b37fea',
        purpleFade: 'rgba(203, 107, 190, 0.3)',
        orange: '#FFA641',
        orangeFade: 'rgba(255, 166, 65, 0.3)',
        green: '#8DCC93',
        greenFade: 'rgba(141, 204, 147, 0.3)',
        yellow: '#ffd67e',
        yellowFade: 'rgba(255, 214, 126, 0.5)',
        red: '#E55050',
        redFade: 'rgba(229, 80, 80, 0.5)',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        shadow: 'rgba(0, 0, 0, 0.1)',
    },
};

const darkTheme = {
    name: 'dark',
    colors: {
        dark: '#2E3D49',
        white: '#FFFFFF',
        background: '#1E1E1E',
        backgroundSecondary: '#2E2E2E',
        text: '#FFFFFF',
        primary: '#5B63EB',
        primaryFade: 'rgba(91, 99, 235, 0.5)',
        primaryFaded: 'rgba(91, 99, 235, 0.3)',
        lightBorder: 'rgba(176, 190, 197, 0.6)',
        border: '#595959',
        borderFade: 'rgba(89, 89, 89, 0.3)',
        stroke: '#DDDDDD',
        strokeFade: 'rgba(176, 190, 197, 0.5)',
        strokeFaded: 'rgba(176, 190, 197, 0.3)',
        purple: '#916bcb',
        purpleFade: 'rgba(203, 107, 190, 0.3)',
        green: '#8DCC93',
        greenFade: 'rgba(141, 204, 147, 0.3)',
        yellow: '#ffa641',
        yellowFade: 'rgba(255, 213, 100, 0.3)',
        orange: '#FFA641',
        orangeFade: 'rgba(255, 166, 65, 0.3)',
        red: '#E55050',
        redFade: 'rgba(229, 80, 80, 0.5)',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        shadow: 'rgba(0, 0, 0, 0.6)',
    },
}

const ThemeContext = createContext(lightTheme);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(darkTheme);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleMediaChange = (e) => {
            setTheme(e.matches ? darkTheme : lightTheme);
        }

        mediaQuery.addEventListener('change', handleMediaChange);
        handleMediaChange(mediaQuery);

        return () => mediaQuery.removeListener(handleMediaChange);
    }, []);

    const updateThemeColor = (themeColors) => {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        if (metaThemeColor) {
            metaThemeColor.setAttribute('content', themeColors.background);
        }
    };

    useEffect(() => {
        updateThemeColor(theme.colors);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme.name === 'light' ? darkTheme : lightTheme);
    }

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};