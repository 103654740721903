import React, { useState, useEffect, useRef } from 'react';
import NavigationHeader from '../components/molecules/NavigationHeader';
import StickyHeader from '../components/atoms/StickyHeader';
import SocialLinks from '../components/molecules/SocialLinks';
import styled from 'styled-components';
import { RESUME_DATA } from '../utils/constants';
import {useTheme} from "../contexts/ThemeContext";
import CustomText from "../components/atoms/CustomText";
import {getLanguageColors} from "../utils/utils";

function useOnScreen(options) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
}

const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const ResumeListItem = styled.li`
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  line-height: 1.3;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  opacity: 1;
  position: relative;
  list-style: none;
  margin-bottom: 4px;
`;

const SchoolName = styled.span`
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
    opacity: 1;
`;

const SchoolYear = styled.span`
  align-self: flex-end;
  position: absolute;
  right: 10px;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text};
  opacity: 1;
`;

const ResumeListItemBigger = styled(ResumeListItem)`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ResumeProjectLanguage = styled.span`
  font-weight: normal;
  font-size: 1rem;
  opacity: 0.8;
`;

const ResumeListItemBullet = styled(ResumeListItem)`
  list-style: circle;
  margin-top: 5px;
  opacity: 0.8;
`;

const ResumeListItemIndent = styled(ResumeListItem)`
  list-style: circle;
  margin-left: 20px;
  margin-top: 6px;
  opacity: 0.8;
`;

const ResumeContainer = styled.div`
  width: 70%;
  transition: margin-left 0.3s ease-in-out;
  margin: 2em;
  margin-top: 6em;
  position: relative;
  
  @media (max-width: 1400px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 3em;
  }
`;

const ResumeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 90%;
`;

const ResumeHeaderText = styled(CustomText)`
    font-size: 2.5rem;
    margin-bottom: 10px;
`;

const ResumeSubHeader = styled(CustomText)`
  display: flex;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.text};
  opacity: 1;
  max-width: 100%;
  flex-wrap: wrap;
`;

const ResumeSection = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  opacity: 1;
  max-width: 95%;
`;

const ResumeListItemSpecial = styled.li`
  font-family: 'Poppins', sans-serif;
  list-style: none;
  line-height: 1.3;
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.text};
  //opacity: 0.8;
`;

const Line = styled.hr`
  border: .5px solid #4d4d4d;
  width: 98.8%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkillsSection = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 7%;
  justify-items: center;
  align-items: center;
  opacity: 1;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 170px;
  }
`;

const Skill = styled(CustomText)`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 8em;
  margin-top: 8px;
  font-size: 1em;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 768px) {
    width: 75%;
    font-size: 1em;
  }
`;

const SkillProgress = styled.progress`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  margin-top: 5px;
  -webkit-appearance: none;
  appearance: none;
  &::-webkit-progress-bar {
    border-radius: 30px;
  }

  &::-webkit-progress-value {
      border-radius: 30px;
      background: linear-gradient(to right, #5C86FF, #5C86FF);
  }
`;

const ProjectFragment = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-top: 8px;

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
  }
`;

const FadedSpan = styled(CustomText)`
  opacity: 0.5;
  margin: 0 5px;
`;

const Link = styled.a`
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.colors.text};
        text-decoration: underline;
    }
`;

const Resume = ({ isMenuOpen, toggleMenu }) => {
  const { theme } = useTheme();
  const [refEducation, isVisibleEducation] = useOnScreen({ threshold: 0 });
  const [refSkills, isVisibleSkills] = useOnScreen({ threshold: 0 });
  const [refWorkExperience, isVisibleWorkExperience] = useOnScreen({ threshold: 0 });
  const [refProjects, isVisibleProjects] = useOnScreen({ threshold: 0 });
  const [refAdditionalInformation, isVisibleAdditionalInformation] = useOnScreen({ threshold: 0 });

  return (
    <AppContainer theme={theme}>
      <NavigationHeader currentPage={"/resume"} toggleMenu={toggleMenu} />
      <ResumeContainer isMenuOpen={isMenuOpen}>
        <ResumeHeader>
          <ResumeHeaderText type="medium">Silas Nevstad</ResumeHeaderText>
          <ResumeSubHeader theme={theme}>
            Boston, MA
            <FadedSpan type="medium">|</FadedSpan>nevstads@gmail.com
            <FadedSpan type="medium">|</FadedSpan><Link theme={theme} href="https://www.linkedin.com/in/silas-nevstad-3091a420b/" style={{color: '#81a1f7'}}>LinkedIn</Link>
            <FadedSpan type="medium">|</FadedSpan><Link theme={theme} href="https://github.com/silasnevstad/"  style={{color: '#c26ceb'}}>Github</Link>
          </ResumeSubHeader>
          <StickyHeader isMenuOpen={isMenuOpen} />
        </ResumeHeader>

        <div className="scrollable-div">
          <ResumeSection ref={refWorkExperience} isVisible={isVisibleWorkExperience} style={{animationDelay: '0s'}}>
            <ResumeSubHeader theme={theme}>Work Experience</ResumeSubHeader>
            <Line />
            <ul style={{paddingLeft: 10}}>
              {RESUME_DATA.workExperience.map((experience, index) => (
                  <ProjectFragment key={index}>
                    <ResumeListItemBigger theme={theme}>{experience.position}</ResumeListItemBigger>
                    <ResumeListItemSpecial theme={theme}>{experience.company}, {experience.location}</ResumeListItemSpecial>
                    <ul>
                      {experience.bulletPoints.map((point, idx) => (
                          <ResumeListItemBullet key={idx} theme={theme}>{point}</ResumeListItemBullet>
                      ))}
                    </ul>
                    {index !== RESUME_DATA.workExperience.length - 1 && <br />}
                  </ProjectFragment>
              ))}
            </ul>
          </ResumeSection>

          <br />

          <ResumeSection ref={refEducation} isVisible={isVisibleEducation} style={{ animationDelay: '0.1s' }} >
            <ResumeSubHeader theme={theme}>Education</ResumeSubHeader>
            <Line />
            <ul style={{paddingLeft: 10}}>
              {RESUME_DATA.education.map((school, index) => (
                <React.Fragment key={index}>
                  <ResumeListItem style={{ animationDelay: `${0.11 + index * 0.01}s` }} theme={theme}>
                    <SchoolName theme={theme}>{school.schoolName}</SchoolName>{school.college && `, ${school.college}`}
                    {window.innerWidth > 800 && <SchoolYear theme={theme}>{school.year}</SchoolYear>}
                  </ResumeListItem>
                  
                  <ResumeListItem style={{ animationDelay: `${0.12 + index * 0.01}s` }} theme={theme}>
                    {school.degree}
                    {window.innerWidth > 800 && <SchoolYear theme={theme}>{school.location}</SchoolYear>}
                  </ResumeListItem>

                  {school.gpa && (
                    <ResumeListItemIndent style={{ animationDelay: `${0.13 + index * 0.01}s` }} theme={theme}>
                      GPA: {school.gpa}
                    </ResumeListItemIndent>
                  )}
                  
                  {school.coursework && (
                    <ResumeListItemIndent style={{ animationDelay: `${0.13}s` }} theme={theme}>
                      Relevant Coursework: {school.coursework.join(', ')}
                    </ResumeListItemIndent>
                  )}
                  
                  {school.activities && (
                    <ResumeListItemIndent style={{ animationDelay: `${0.14}s` }} theme={theme}>
                      Activities: {school.activities.join(', ')}
                    </ResumeListItemIndent>
                  )}
                  
                  <br />
                </React.Fragment>
              ))}
            </ul>
          </ResumeSection>

          <br />

          <ResumeSection ref={refSkills} isVisible={isVisibleSkills} style={{ animationDelay: '0s' }}>
            <ResumeSubHeader theme={theme}>Skills</ResumeSubHeader>
            <Line />
            <SkillsSection>
                {RESUME_DATA.skills.map(skill => (
                    <Skill key={skill.name} className="skills-inline" theme={theme}>
                        {skill.name}
                        <SkillProgress value={skill.value} max="100" />
                    </Skill>
                ))}
            </SkillsSection>
          </ResumeSection>

          <br />

          <ResumeSection ref={refProjects} isVisible={isVisibleProjects} style={{animationDelay: '0s'}}>
            <ResumeSubHeader theme={theme}>Projects</ResumeSubHeader>
            <Line />
            <ul style={{paddingLeft: 10}}>
              {RESUME_DATA.projects.map((project, index) => (
                <ProjectFragment key={index} style={{animationDelay: `${0.1 + index * 0.01}s`}}>
                  <ResumeListItemBigger theme={theme}>
                    {project.title} <ResumeProjectLanguage>|</ResumeProjectLanguage> {project.languages.map((language, idx) => (
                      <ResumeProjectLanguage style={{ color: getLanguageColors(language)[0] }}>{language}<ResumeProjectLanguage  style={{color: theme.colors.text}}>{idx !== project.languages.length - 1 && ' | '}</ResumeProjectLanguage></ResumeProjectLanguage>
                    ))}
                  </ResumeListItemBigger>
                  <ul>
                    {project.bulletPoints.map((point, idx) => (
                      <ResumeListItemBullet key={idx} theme={theme}>{point}</ResumeListItemBullet>
                    ))}
                  </ul>
                  {index !== RESUME_DATA.projects.length - 1 && <br />}
                </ProjectFragment>
              ))}
            </ul>
          </ResumeSection>

          <br />

          <ResumeSection ref={refAdditionalInformation} isVisible={isVisibleAdditionalInformation} style={{animationDelay: '0s'}}>
            <ResumeSubHeader theme={theme}>Additional Information</ResumeSubHeader>
            <Line />
            <ul>
              <ResumeListItemBullet theme={theme}> Related Experience: 2nd Place High School Hackathon Winner (2019) </ResumeListItemBullet>
              <ResumeListItemBullet theme={theme}> Interests: Chess, Music Production, Playing Piano, Hockey </ResumeListItemBullet>
              <ResumeListItemBullet theme={theme}> Languages: English, German, Norwegian </ResumeListItemBullet>
            </ul>
          </ResumeSection>

        </div>

        <div style={{height: "10vh"}}></div>
      </ResumeContainer>

      <SocialLinks />
    </AppContainer>
  );
};

export default Resume;
