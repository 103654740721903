import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styled from 'styled-components';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.lightBorder};
  padding: 10px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  align-self: center;
  color: ${(props) => props.theme.colors.text};
  font-size: 1.1em;
  font-family: 'Poppins', serif;
  font-weight: 500;
  resize: none;
  overflow: hidden;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`;

// Updated to adjust the icon placement
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.7;
`;

const CustomRegularInput = ({ type, name, value, onChange, onKeyPress, placeholder, style, secure, toggleSecure, icon: Icon }) => {
    const { theme } = useTheme();

    const handleChange = (event) => {
        if (type === 'number') {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <InputContainer theme={theme}>
            {Icon && (
                <IconWrapper theme={theme}>
                    <Icon />
                </IconWrapper>
            )}
            <Input
                type={secure ? 'password' : 'text'}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyPress}
                placeholder={placeholder}
                theme={theme}
                style={style}
            />
            {type === 'password' && (
                <IconWrapper onClick={toggleSecure} style={{marginLeft: 'auto', marginRight: '-10px'}} theme={theme}>
                    {secure ? <PiEyeSlashBold /> : <PiEyeBold />}
                </IconWrapper>
            )}
        </InputContainer>
    );
};

export default CustomRegularInput;