import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from '../atoms/CustomText';
import { MdOutlineNewReleases, MdTrendingUp } from 'react-icons/md';
import {animated, useSpring} from "react-spring";

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 20px;
  border: 0.5px solid ${({ theme }) => theme.colors.borderFade};
  padding: 5px;
  box-shadow: 0 2px 4px ${({ theme }) => theme.colors.shadow};
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SliderOption = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 10px 20px;
  border-radius: 15px;
  color: ${({ isSelected, theme }) => isSelected ? theme.colors.white : theme.colors.text};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 3;

  &:hover {
    // background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    color: ${({ theme }) => theme.colors.white};
  }
  
  @media (max-width: 768px) {
    padding: 6px 12px;
    gap: 8px;
  }
`;

const BackgroundSlider = styled(animated.div)`
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 20px;
  transition: all 0.1s ease;
  z-index: 2;
`;

const TrendingIcon = styled(MdTrendingUp)`
    color: ${({ theme }) => theme.colors.text};
`;

const RecentIcon = styled(MdOutlineNewReleases)`
    color: ${({ theme }) => theme.colors.text};
`;

const SliderToggle = ({ handleSortChange }) => {
    const { theme } = useTheme();
    const [selected, setSelected] = useState('popular');
    const handleSelectionChange = (selection) => {
        setSelected(selection);
        handleSortChange(selection);
    };

    const slidingBackground = useSpring({
        to: { transform: `translateX(${selected === 'date' ? 50 : -50}%)` },
        from: { transform: 'translateX(-50%)' },
        config: { mass: 1, tension: 210, friction: 20 },
    });

    return (
        <SliderContainer theme={theme}>
            <BackgroundSlider style={slidingBackground} theme={theme} />
            <SliderOption
                isSelected={selected === 'popular'}
                onClick={() => handleSelectionChange('popularity')}
                theme={theme}
            >
                <TrendingIcon theme={theme} />
                <CustomText type="medium">Most Popular</CustomText>
            </SliderOption>
            <SliderOption
                isSelected={selected === 'recent'}
                onClick={() => handleSelectionChange('date')}
                theme={theme}
            >
                <RecentIcon theme={theme} />
                <CustomText type="medium">Most Recent</CustomText>
            </SliderOption>

        </SliderContainer>
    );
};

export default SliderToggle;
