import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";
import ChessGame from "../components/organisms/ChessGame";
import NavigationHeader from '../components/molecules/NavigationHeader';

const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const ChessResizeContainer = styled.div`
    margin-top: 3.2em;

    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 1em;
        width: 100%;
    }
`;

const Chess = ({ isMenuOpen, toggleMenu }) => {
    const { theme } = useTheme();

    return (
        <AppContainer theme={theme}>
            <NavigationHeader currentPage={"/chess"} toggleMenu={toggleMenu} />
            <ChessResizeContainer isMenuOpen={isMenuOpen}>
                <ChessGame isMenuOpen={isMenuOpen} />
            </ChessResizeContainer>
        </AppContainer>
    );
};

export default Chess;