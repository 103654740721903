import React, { useEffect, useState } from 'react';
import './assets/fonts/fonts.css';
import FontFaceObserver from 'fontfaceobserver';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import LoadingNoTheme from './screens/LoadingNoTheme';
import Home from './screens/Home';
import Projects from "./screens/Projects";
import Resume from "./screens/Resume";
import Chess from "./screens/Chess";
import Contact from "./screens/Contact";
import HomeworkCode from "./screens/HomeworkCode";

const App = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      const fontObserver = new FontFaceObserver('Poppins');
      try {
        await fontObserver.load();
        setFontsLoaded(true);
      } catch (error) {
        console.error('Font loading error:', error);
      }
    };

    loadFonts();
  }, []);

  if (!fontsLoaded) {
    return <LoadingNoTheme />;
  }

  return (
      <Router>
        <ThemeProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Projects />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/chess" element={<Chess />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/is4200/hw2" element={<HomeworkCode />} />
          </Routes>
        </ThemeProvider>
      </Router>
  );
};

export default App;
