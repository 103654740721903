import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from "../contexts/ThemeContext";
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import NavigationHeader from "../components/molecules/NavigationHeader";
import CustomText from "../components/atoms/CustomText";
import {hw2} from "../utils/homeworks";

const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  padding-bottom: 100px;
`;

const HomeworkHeader = styled(CustomText)`
  font-size: 2em;
  margin-bottom: 20px;
`;

const HomeworkLink = styled.a`
    font-size: 1.5em;
    margin: 10px 0;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    transition: all 0.3s ease;
  
    &:hover {
        transform: scale(1.05);
    }
  
    &:active {
        transform: scale(0.95);
    }
`;

const CodeBlock = styled.pre`
  background-color: ${({ theme }) => theme.colors.codeBackground || '#f5f5f5'};
  color: ${({ theme }) => theme.colors.codeText || '#333'};
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 800px;
  overflow-x: auto;
  margin-top: 50px;
`;

const HomeworkCode = () => {
    const { theme } = useTheme();
    const [codeString, setCodeString] = useState('');

    const hw2ReportLink = "https://docs.google.com/document/d/1lDCy1x5ZpS4jOb0bHBDxHiI_D88aTKq0x4YlRykr9Vk/edit?usp=sharing";

    useEffect(() => {
        const loadFileContent = async () => {
            try {
                const content = hw2;
                setCodeString(content);
                Prism.highlightAll();
            } catch (error) {
                console.error("Failed to load the file content:", error);
                setCodeString("Failed to load the content.");
            }
        };

        loadFileContent();
    }, []);

    return (
        <AppContainer theme={theme}>
            <NavigationHeader />
            <InnerContainer>
                <HomeworkHeader>
                    HW2 - IS4200
                </HomeworkHeader>
                <HomeworkLink href={hw2ReportLink} target="_blank" theme={theme}>
                    Link to report
                </HomeworkLink>
                <CodeBlock theme={theme}>
                    <code className="language-python">{codeString}</code>
                </CodeBlock>
            </InnerContainer>
        </AppContainer>
    );
};

export default HomeworkCode;