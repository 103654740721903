import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from '../atoms/CustomText';
import { TITLES } from '../../utils/constants';

const BlinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingCursor = styled.span.attrs(props => ({}))`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 2px;
  animation: ${({ isBlinking }) => isBlinking ? css`${BlinkAnimation} 0.8s ease-in-out infinite` : 'none'};
  opacity: ${({ isBlinking }) => isBlinking ? '1' : '1'};
`;

const TypingText = ({ startingText = '', typeStyle = 'regular', style = {}, typingSpeed = 200, erasingSpeed = 100 }) => {
    const { theme } = useTheme();

    const [currentTitle, setCurrentTitle] = useState(() => TITLES[Math.floor(Math.random() * TITLES.length)]);
    const [visibleText, setVisibleText] = useState(startingText);
    const [isTyping, setIsTyping] = useState(true);
    const [isBlinking, setIsBlinking] = useState(false);
    const [lastTitleIndex, setLastTitleIndex] = useState(TITLES.indexOf(currentTitle));

    // Function to pick a new title, different from the last one
    const pickNewTitle = () => {
        let newIndex;
        do {
            newIndex = Math.floor(Math.random() * TITLES.length);
        } while (newIndex === lastTitleIndex);
        setLastTitleIndex(newIndex);
        return TITLES[newIndex];
    };

    useEffect(() => {
        let timeoutId;

        if (isTyping) {
            if (visibleText.length < startingText.length + currentTitle.length) {
                // Ensure we're adding characters only after startingText
                const nextCharIndex = visibleText.length - startingText.length;
                const nextChar = currentTitle.charAt(nextCharIndex); // Use charAt to safely handle out-of-bounds
                const timeoutDuration = typingSpeed + Math.random() * 50;
                timeoutId = setTimeout(() => setVisibleText((prevText) => prevText + nextChar), timeoutDuration);
            } else {
                // After typing is complete, start erasing after a brief pause
                setIsBlinking(true);
                timeoutId = setTimeout(() => setIsTyping(false), 3000);
            }
        } else {
            if (visibleText.length > startingText.length) {
                // Erase faster than typing
                timeoutId = setTimeout(() => setVisibleText((prevText) => prevText.slice(0, -1)), erasingSpeed);
            } else {
                // After erasing is complete, pick a new title and start typing
                const newTitle = pickNewTitle();
                setCurrentTitle(newTitle);
                setIsTyping(true);
                setIsBlinking(false);
            }
        }

        return () => clearTimeout(timeoutId);
    }, [visibleText, isTyping, currentTitle, startingText, typingSpeed, erasingSpeed]);

    // Reset visibleText when currentTitle changes, appending startingText to ensure it's preserved
    useEffect(() => {
        setVisibleText(startingText);
    }, [currentTitle, startingText]);

    return (
        <CustomText type={typeStyle} style={{...style, display: 'flex', alignItems: 'center'}}>
            {visibleText}
            <BlinkingCursor theme={theme} isBlinking={isBlinking}>|</BlinkingCursor>
        </CustomText>
    );
};

export default TypingText;
