import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import {formatRequests, getProjectRequestsText} from '../../utils/utils';
import ProjectLanguages from './ProjectLanguages';
import CountUp from 'react-countup';
import CustomText from "../atoms/CustomText";

const CardLarge = styled.div`
    padding: 1px 5px;
    margin: 10px;

    display: flex;
    flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    align-items: left;
    text-align: left;

    position: relative;

    max-height: 90vh;

    animation: fadein 0.5s;

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

const CardLargeHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CardLargeHeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
`;

const ProjectLogo = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin-right: 15px;
`;

const ProjectTitle = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.6rem;
    margin: 0;
    font-family: 'Inter', sans-serif;

    @media (max-width: 768px) {
        font-size: 1.4rem;
    }

    @media (prefers-color-scheme: light) {
        // color: #111;
    }
`;

const ProjectDescription = styled.p`
    font-size: 1.2rem;
    margin: 0;
    margin-top: 25px;
    opacity: 0.8;
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: 0.4px;
    white-space: pre-wrap;
    overflow-y: auto;

    @media (max-width: 768px) {
        overflow-y: scroll;
        overflow-x: hidden;
        font-size: 1rem;
    }

    @media (prefers-color-scheme: light) {
        // color: #111;
    }
`;

const ProjectDeveloper = styled.p`
    font-size: 1rem;
    margin: 0;
    margin-top: 10px;
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Inter', sans-serif;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (prefers-color-scheme: light) {
        // color: #111;
    }
`;

const ProjectFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
`;

const ProjectFooterLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ProjectFooterRight = styled.div`
    display: flex;
    flex-direction: row;
    
`;

const ProjectFooterRequests = styled.p`
    display: flex;
    align-items: center;
    gap: 0.3em;
    font-size: 1em;
    opacity: 0.6;
    color: ${({theme}) => theme.colors.stroke};
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.4px;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (prefers-color-scheme: light) {
        // color: #111;
    }
`;

const ProjectVersionLabel = styled(CustomText)`
    margin-top: 0.2em;
    font-size: .9em;
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.stroke};
`;

const ProjectVisitButton = styled.button`
    --primary-color: #bbb;
    --hovered-color: #81a1f7;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    position: relative;
    display: flex;
    font-weight: 500;
    font-size: 20px;
    gap: 0.2rem;
    align-items: center;

    & p {
        margin: 0;
        position: relative;
        font-size: 20px;
        color: var(--primary-color)
    }

    & p::before {
        position: absolute;
        content: "Visit";
        width: 0%;
        inset: 0;
        color: var(--hovered-color);
        overflow: hidden;
        transition: 0.3s ease-out;
    }

    &::after {
        position: absolute;
        content: "";
        width: 0;
        left: 0;
        bottom: -7px;
        background: var(--hovered-color);
        height: 0px;
    }
    
    &:hover p::before {
        width: 100%;
    }

    &:hover svg {
        opacity: 1;
        transform: translateX(4px);
        color: var(--hovered-color) 
    }

    &:hover {
        cursor: pointer;
    }

    & svg {
        opacity: 0;
        color: var(--primary-color);
        transition: 0.2s;
        position: relative;
        width: 15px;
        transition-delay: 0.2s;
    }
`;

const getPaperContent = async (filename) => {
    try {
        const response = await fetch(`/papers/${filename}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const content = await response.text();
        return content;
    } catch (e) {
        console.error('There has been a problem with your fetch operation: ' + e.message);
    }
}

const ProjectCardExpanded = ({ project }) => {
    const { theme } = useTheme();
    const [paperContent, setPaperContent] = useState('');

    useEffect(() => {
        if (project.content) {
            getPaperContent(project.content).then(content => setPaperContent(content));
        }
    }, [project]);

    return (
        <CardLarge theme={theme}>
            <CardLargeHeader>
                {project.logo && <ProjectLogo src={require('../../assets/images/logos/' + project.logo)} />}
                <CardLargeHeaderInfo>
                    <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <ProjectTitle theme={theme}>{project.title}</ProjectTitle>
                        {/* <ProjectVersion>{project.version}</ProjectVersion> */}
                    </span>

                    <ProjectLanguages languages={project.languages} />
                    <ProjectDeveloper theme={theme}>{project.developers}</ProjectDeveloper>
                </CardLargeHeaderInfo>
            </CardLargeHeader>
            {paperContent && <ProjectDescription theme={theme}>{paperContent}</ProjectDescription>}
            <ProjectDescription theme={theme}>{project.description}</ProjectDescription>


            <ProjectFooter>
                <ProjectFooterLeft>
                    {project.url !== '' &&
                        <ProjectVisitButton onClick={() => window.open(project.url, '_blank')}>
                            <p>Visit</p>
                            <svg stroke-width="4" stroke="currentColor" viewBox="0 0 24 24" fill="none" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 5l7 7m0 0l-7 7m7-7H3" stroke-linejoin="round" stroke-linecap="round"></path>
                            </svg>
                        </ProjectVisitButton>
                    }
                </ProjectFooterLeft>
                <ProjectFooterRight>
                    {!paperContent &&
                        <ProjectFooterRequests theme={theme}>
                            <CountUp
                                start={0}
                                end={project.requests}
                                duration={1.5}
                                formattingFn={number => formatRequests(number, project.title === "VSCode GPT")}
                            />
                            <ProjectVersionLabel theme={theme}>
                                {getProjectRequestsText(project.title === "VSCode GPT")}
                            </ProjectVersionLabel>
                        </ProjectFooterRequests>
                    }
                </ProjectFooterRight>
            </ProjectFooter>
        </CardLarge>
    );
};

export default ProjectCardExpanded;
