import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import '../../assets/fonts/fonts.css';

const CustomText = ({ type = 'regular', style, children, ...props }) => {
    const { theme } = useTheme();

    const fontStyles = {
        regular: { fontFamily: 'Poppins', fontWeight: 400 },
        bold: { fontFamily: 'Poppins', fontWeight: 700 },
        italic: { fontFamily: 'Poppins', fontStyle: 'italic' },
        light: { fontFamily: 'Poppins', fontWeight: 300 },
        medium: { fontFamily: 'PoppinsMedium', fontWeight: 500 },
        semiBold: { fontFamily: 'Poppins', fontWeight: 600 },
        black: { fontFamily: 'Poppins', fontWeight: 900 },
    };

    const combinedStyle = { ...fontStyles[type], color: theme.colors.text, ...style };


    return (
        <div style={combinedStyle} {...props}>
            {children}
        </div>
    );
};

export default CustomText;