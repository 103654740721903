import { useState } from "react";
import NavigationHeader from '../components/molecules/NavigationHeader';
import SocialLinks from '../components/molecules/SocialLinks';
import styled, { keyframes } from 'styled-components';
import emailjs from 'emailjs-com';
import {useTheme} from "../contexts/ThemeContext";
import CustomButton from "../components/atoms/CustomButtom";
import { PiUserBold, PiPaperPlaneBold, PiEnvelopeBold } from "react-icons/pi";
import CustomInput from "../components/atoms/CustomInput";
import CustomTextArea from "../components/atoms/CustomTextArea";

const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const fadeAndDropInFromCeiling = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const ContactResizeContainer = styled.div`
  margin-top: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 4em;
    width: 100%;
  }
`;

const ContactInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  gap: 10px;

  @media (max-width: 1400px) {
    width: 80%;
  }
`;

const ContactButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
  animation: ${fadeAndDropInFromCeiling} 0.2s ease-in-out forwards;
  opacity: 0;
`;

const PaperPlaneIcon = styled(PiPaperPlaneBold)`
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.white};
`;

const Contact = () => {
    const { theme } = useTheme();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!name || !email || !message) {
            setError('Please fill in all fields.');
            setLoading(false);
            setTimeout(() => {
                setError('');
            }, 2000);
            return;
        }

        // Send the form data to EmailJS
        emailjs.send('service_hc9akgh', 'template_2cjai8y', { name, email, message }, '5zJalJbOIpV3-eipy')
            .then((response) => {
                setLoading(false);
                setSuccess(true);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <AppContainer theme={theme}>
            <NavigationHeader />
            <ContactResizeContainer>
                <ContactInputContainer>
                    <CustomInput
                        theme={theme}
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        icon={PiUserBold}
                    />
                    <CustomInput
                        theme={theme}
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        icon={PiEnvelopeBold}
                    />
                    <CustomTextArea
                        theme={theme}
                        type="text"
                        placeholder="Type your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        minRows={5}
                    />
                    <ContactButtonContainer style={{animationDelay: '0.3s'}}>
                        <CustomButton onClick={handleSubmit} disabled={loading || success} type="small">
                            {loading ? 'Sending...' : success ? 'Sent!' : error ? error : 'Send Message'}
                            <PaperPlaneIcon theme={theme} />
                        </CustomButton>
                    </ContactButtonContainer>
                </ContactInputContainer>
            </ContactResizeContainer>
            <SocialLinks />
        </AppContainer>
    );
};

export default Contact;